<template>
  <div>
    <vm-table ref="vmTable" :filter.sync="search" url="potentialFaultSetting">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
        <el-button :loading="operateLoad" @click="handleStatisticsClick">
          {{ $t("potentialFaultSetting.operate") }}
        </el-button>
      </template>
      <el-table-column prop="name" :label="$t('potentialFaultSetting.name')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="warningValue" :label="$t('potentialFaultSetting.warningValue')"
        width="100" align="center"></el-table-column>
      <el-table-column prop="statPeriod" :label="$t('potentialFaultSetting.statPeriod')"  width="140" align="center"></el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">{{ $t("common.delete") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList($event ? 1 : currentPage)"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "./PotentialFaultSettingEdit";

const moduleName = "potentialFaultSetting";

export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: this.$auth(70),
      operateLoad: false,
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("potentialFaultSetting.potentialFaultSetting") + " " + row.name + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(res => {
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
    handleStatisticsClick() {
      this.operateLoad = true;
      this.$api.getData(moduleName + "/stat").then(res => {
        if (res.data) {
          this.$message.success(this.$t("potentialFaultSetting.tip.operateSuccess"));
        } else {
          this.$message.error(this.$t("potentialFaultSetting.tip.operateError"));
        }
        this.operateLoad = false;
      }).catch(() => {
        this.operateLoad = false;
        this.$message.error(this.$t("potentialFaultSetting.tip.operateError"));
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
